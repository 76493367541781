import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { CheckCircle2, Loader2 } from 'lucide-react';
import { getPaymentDetails, PaymentData } from '../lib/stripe';

export default function SuccessPage() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState<PaymentData | null>(null);

  useEffect(() => {
    async function loadPaymentDetails() {
      const sessionId = searchParams.get('session_id');
      if (sessionId) {
        const details = await getPaymentDetails(sessionId);
        setPayment(details);
      }
      setLoading(false);
    }

    loadPaymentDetails();
  }, [searchParams]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="text-center">
          <Loader2 className="h-8 w-8 animate-spin text-emerald-600 mx-auto mb-4" />
          <p className="text-gray-600">Processing your payment...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-sm p-8 text-center">
        <CheckCircle2 className="h-12 w-12 text-emerald-600 mx-auto mb-6" />
        <h1 className="text-2xl font-bold text-gray-900 mb-4">
          Thank You for Your Tip!
        </h1>
        {payment && (
          <p className="text-gray-600 mb-2">
            Amount: ${(payment.amount / 100).toFixed(2)}
          </p>
        )}
        <p className="text-gray-600 mb-8">
          Your payment has been processed successfully.
        </p>
        <Link
          to="/"
          className="inline-block bg-emerald-600 text-white px-6 py-3 rounded-lg hover:bg-emerald-700"
        >
          Return Home
        </Link>
      </div>
    </div>
  );
}