import { loadStripe } from '@stripe/stripe-js';
import { collection, addDoc, getDocs, query, where, getFirestore, DocumentReference, getDoc } from 'firebase/firestore';

// Initialize Stripe with publishable key
export const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

export interface PaymentData {
  id: string;
  amount: number;
  status: string;
  metadata?: {
    recipientId?: string;
    type?: string;
  };
}

// Create a checkout session for tips
export async function createTipCheckout(recipientId: string, amount: number) {
  if (!recipientId || amount <= 0) {
    throw new Error('Invalid recipient ID or amount');
  }

  try {
    const db = getFirestore();
    const checkoutSessionRef = collection(db, 'checkout_sessions');
    
    // Create the session data
    const sessionData = {
      price_data: {
        currency: 'usd',
        unit_amount: Math.round(amount * 100),
        product_data: {
          name: 'Tip',
        },
      },
      success_url: `${window.location.origin}/success?session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${window.location.origin}/failure?recipient_id=${recipientId}`,
      metadata: {
        recipientId,
        type: 'tip'
      },
      status: 'pending',
      created_at: new Date().toISOString()
    };

    // Create the checkout session document
    const docRef = await addDoc(checkoutSessionRef, sessionData);

    // Poll for the session URL with exponential backoff
    const maxAttempts = 10;
    const baseDelay = 500;
    const maxDelay = 3000;
    
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      const docSnapshot = await getDoc(docRef);
      const data = docSnapshot.data();
      
      if (data?.url) {
        return { url: data.url };
      }
      
      if (data?.error) {
        throw new Error(data.error);
      }

      if (data?.status === 'failed') {
        throw new Error('Failed to create checkout session');
      }
      
      // Calculate delay with exponential backoff and jitter
      const jitter = Math.random() * 200;
      const delay = Math.min(baseDelay * Math.pow(1.5, attempt) + jitter, maxDelay);
      await new Promise(resolve => setTimeout(resolve, delay));
    }

    throw new Error('The payment service is temporarily unavailable. Please try again in a few moments.');
  } catch (error) {
    console.error('Error creating checkout:', error);
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('Failed to create checkout session');
  }
}

// Get payment details
export async function getPaymentDetails(sessionId: string): Promise<PaymentData | null> {
  try {
    const db = getFirestore();
    const paymentsRef = collection(db, 'payments');
    const q = query(paymentsRef, where('checkout_session_id', '==', sessionId));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      return null;
    }

    const doc = snapshot.docs[0];
    const data = doc.data();

    return {
      id: doc.id,
      amount: data.amount || 0,
      status: data.status || 'unknown',
      metadata: data.metadata || {}
    };
  } catch (error) {
    console.error('Error getting payment details:', error);
    return null;
  }
}