import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { XCircle, ArrowLeft } from 'lucide-react';

export default function FailurePage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const recipientId = searchParams.get('recipient_id');

  const handleReturn = () => {
    if (recipientId) {
      navigate(`/tip/${recipientId}`);
    } else {
      navigate('/');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-sm p-8 text-center">
        <XCircle className="h-12 w-12 text-red-500 mx-auto mb-6" />
        <h1 className="text-2xl font-bold text-gray-900 mb-4">
          Payment Failed
        </h1>
        <p className="text-gray-600 mb-8">
          We couldn't process your payment. Please try again.
        </p>
        <button
          onClick={handleReturn}
          className="inline-flex items-center justify-center bg-emerald-600 text-white px-6 py-3 rounded-lg hover:bg-emerald-700 transition-colors"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Return to Tip Page
        </button>
      </div>
    </div>
  );
}