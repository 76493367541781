import React from 'react';
import { Link } from 'react-router-dom';
import { DollarSign, TrendingUp, Users, Calendar, Link as LinkIcon, Download, Settings } from 'lucide-react';
import { QRCodeSVG } from 'qrcode.react';
import { useAuth } from '../contexts/AuthContext';

export default function Dashboard() {
  const { currentUser } = useAuth();
  const tipLink = currentUser ? `${window.location.origin}/tip/${currentUser.uid}` : '';
  const [copied, setCopied] = React.useState(false);

  const copyTipLink = async () => {
    try {
      await navigator.clipboard.writeText(tipLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy link:', err);
    }
  };

  const downloadQR = () => {
    const canvas = document.querySelector('canvas');
    if (!canvas) return;

    try {
      const pngUrl = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = 'tiptree-qr.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.error('Failed to download QR code:', err);
    }
  };

  if (!currentUser) {
    return (
      <div className="p-6 text-center">
        <p>Please log in to view your dashboard.</p>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
          <Link
            to="/profile"
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700"
          >
            <Settings className="h-4 w-4 mr-2" />
            Edit Profile
          </Link>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div className="bg-white rounded-lg shadow-sm p-4">
            <p className="text-sm text-gray-500 mb-1">Your Tip Link</p>
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900 truncate">{tipLink}</p>
              <button
                onClick={copyTipLink}
                className="flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
              >
                <LinkIcon className="h-4 w-4 mr-2" />
                {copied ? 'Copied!' : 'Copy Link'}
              </button>
            </div>
          </div>
          
          <div className="bg-white rounded-lg shadow-sm p-4">
            <p className="text-sm text-gray-500 mb-1">Your QR Code</p>
            <div className="flex items-center justify-between">
              <div className="bg-white p-2 rounded-lg">
                <QRCodeSVG
                  value={tipLink}
                  size={100}
                  level="H"
                  includeMargin={true}
                />
              </div>
              <button
                onClick={downloadQR}
                className="flex items-center px-4 py-2 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors"
              >
                <Download className="h-4 w-4 mr-2" />
                Download QR
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center">
            <DollarSign className="h-6 w-6 text-emerald-600" />
            <h3 className="ml-2 text-sm font-medium text-gray-500">Total Tips</h3>
          </div>
          <p className="mt-2 text-2xl font-semibold text-gray-900">$0.00</p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center">
            <Calendar className="h-6 w-6 text-emerald-600" />
            <h3 className="ml-2 text-sm font-medium text-gray-500">Monthly Tips</h3>
          </div>
          <p className="mt-2 text-2xl font-semibold text-gray-900">$0.00</p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center">
            <TrendingUp className="h-6 w-6 text-emerald-600" />
            <h3 className="ml-2 text-sm font-medium text-gray-500">Average Tip</h3>
          </div>
          <p className="mt-2 text-2xl font-semibold text-gray-900">$0.00</p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center">
            <Users className="h-6 w-6 text-emerald-600" />
            <h3 className="ml-2 text-sm font-medium text-gray-500">Total Tippers</h3>
          </div>
          <p className="mt-2 text-2xl font-semibold text-gray-900">0</p>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Recent Tips</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">From</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td colSpan={3} className="px-6 py-4 text-sm text-gray-500 text-center">
                    No tips received yet
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}