import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  TreePine, 
  Loader2, 
  AlertCircle,
  Plus,
  Minus,
  Twitter,
  Instagram,
  Globe,
  Wallet,
  Bitcoin,
  CircleDollarSign,
  CreditCard,
  Banknote
} from 'lucide-react';
import { createTipCheckout } from '../lib/stripe';
import { getUserProfile } from '../services/userService';
import { UserProfile } from '../services/userService';
import LoadingSpinner from './LoadingSpinner';

export default function TipPage() {
  const { tipPageId } = useParams();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [amount, setAmount] = useState(5);

  useEffect(() => {
    let mounted = true;

    async function loadProfile() {
      if (!tipPageId) {
        setError('Invalid tip page');
        setLoading(false);
        return;
      }

      try {
        const userProfile = await getUserProfile(tipPageId);
        if (!userProfile) {
          setError('Profile not found');
          return;
        }
        if (mounted) {
          setProfile(userProfile);
        }
      } catch (err) {
        console.error('Error loading profile:', err);
        if (mounted) {
          setError('Failed to load profile');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    loadProfile();

    return () => {
      mounted = false;
    };
  }, [tipPageId]);

  const handleTipSubmit = async () => {
    if (!profile || !tipPageId || processing || amount <= 0) return;
    
    setProcessing(true);
    setError('');

    try {
      const session = await createTipCheckout(tipPageId, amount);
      if (session?.url) {
        window.location.href = session.url;
      } else {
        throw new Error('Failed to create checkout session');
      }
    } catch (err) {
      console.error('Payment error:', err);
      setError(err instanceof Error ? err.message : 'Failed to process payment. Please try again.');
    } finally {
      setProcessing(false);
    }
  };

  const handleAmountChange = (delta: number) => {
    setAmount(prev => Math.max(1, Math.min(1000, prev + delta)));
  };

  const handleCustomAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value)) {
      setAmount(Math.max(1, Math.min(1000, value)));
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !profile) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-sm p-6 text-center">
          <TreePine className="h-12 w-12 text-emerald-600 mx-auto mb-4" />
          <h1 className="text-xl font-semibold text-gray-900 mb-2">Oops!</h1>
          <p className="text-gray-600">{error || 'Profile not found'}</p>
        </div>
      </div>
    );
  }

  const { customStyles = { primaryColor: '#059669', backgroundColor: '#f9fafb' } } = profile;

  return (
    <div 
      className="min-h-screen flex items-center justify-center p-4"
      style={{ backgroundColor: customStyles.backgroundColor }}
    >
      <div className="max-w-md w-full bg-white rounded-lg shadow-sm p-6">
        <div className="text-center mb-8">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">
            {profile.displayName}
          </h1>
          {profile.bio && (
            <p className="text-gray-600 mb-6">{profile.bio}</p>
          )}
          
          {/* Social Links */}
          {profile.socialLinks && Object.keys(profile.socialLinks).length > 0 && (
            <div className="flex justify-center space-x-4 mb-6">
              {profile.socialLinks.x && (
                <a
                  href={profile.socialLinks.x}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-gray-900"
                  title="X (Twitter)"
                >
                  <Twitter className="h-5 w-5" />
                </a>
              )}
              {profile.socialLinks.instagram && (
                <a
                  href={profile.socialLinks.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-pink-500"
                  title="Instagram"
                >
                  <Instagram className="h-5 w-5" />
                </a>
              )}
              {profile.socialLinks.website && (
                <a
                  href={profile.socialLinks.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-gray-900"
                  title="Website"
                >
                  <Globe className="h-5 w-5" />
                </a>
              )}
            </div>
          )}

          {/* Tip Form */}
          <div className="mb-8">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Send a Tip</h2>
            <div className="flex items-center justify-center space-x-4 mb-4">
              <button
                onClick={() => handleAmountChange(-1)}
                className="p-2 rounded-full hover:bg-gray-100"
                aria-label="Decrease amount"
              >
                <Minus className="h-5 w-5" />
              </button>
              <div className="relative">
                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">$</span>
                <input
                  type="number"
                  value={amount}
                  onChange={handleCustomAmount}
                  className="w-24 text-center py-2 px-6 border rounded-lg"
                  min="1"
                  max="1000"
                  aria-label="Tip amount"
                />
              </div>
              <button
                onClick={() => handleAmountChange(1)}
                className="p-2 rounded-full hover:bg-gray-100"
                aria-label="Increase amount"
              >
                <Plus className="h-5 w-5" />
              </button>
            </div>
            
            {error && (
              <div className="mb-4 flex items-center justify-center text-red-600 bg-red-50 p-3 rounded-lg">
                <AlertCircle className="h-5 w-5 mr-2" />
                {error}
              </div>
            )}
            
            <button
              onClick={handleTipSubmit}
              disabled={processing}
              className="w-full bg-emerald-600 text-white py-3 px-4 rounded-lg hover:bg-emerald-700 transition-colors disabled:opacity-50 flex items-center justify-center"
            >
              {processing ? (
                <>
                  <Loader2 className="h-5 w-5 mr-2 animate-spin" />
                  Processing...
                </>
              ) : (
                'Continue to Payment'
              )}
            </button>
          </div>

          {/* Payment Links */}
          {profile.paymentLinks && Object.keys(profile.paymentLinks).length > 0 && (
            <div className="space-y-3 mt-8">
              <h3 className="text-sm font-medium text-gray-700">Alternative Payment Methods</h3>
              {profile.paymentLinks.paypal && (
                <a
                  href={profile.paymentLinks.paypal}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center space-x-2 p-2 rounded-lg border border-gray-200 hover:border-blue-500 hover:text-blue-500 transition-colors"
                >
                  <CircleDollarSign className="h-5 w-5" />
                  <span>Pay with PayPal</span>
                </a>
              )}
              {profile.paymentLinks.revolut && (
                <a
                  href={profile.paymentLinks.revolut}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center space-x-2 p-2 rounded-lg border border-gray-200 hover:border-blue-600 hover:text-blue-600 transition-colors"
                >
                  <CreditCard className="h-5 w-5" />
                  <span>Pay with Revolut</span>
                </a>
              )}
              {profile.paymentLinks.wise && (
                <a
                  href={profile.paymentLinks.wise}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-center space-x-2 p-2 rounded-lg border border-gray-200 hover:border-green-500 hover:text-green-500 transition-colors"
                >
                  <Banknote className="h-5 w-5" />
                  <span>Pay with Wise</span>
                </a>
              )}
            </div>
          )}

          {/* Crypto Wallets */}
          {profile.walletAddresses && Object.keys(profile.walletAddresses).length > 0 && (
            <div className="mt-8 space-y-3">
              <h3 className="text-sm font-medium text-gray-700">Crypto Wallets</h3>
              {profile.walletAddresses.ethereum && (
                <div className="flex flex-col items-center p-3 rounded-lg border border-gray-200">
                  <div className="flex items-center space-x-2 mb-1">
                    <Wallet className="h-5 w-5 text-purple-600" />
                    <span className="font-medium">Ethereum</span>
                  </div>
                  <code className="text-sm text-gray-600 break-all">
                    {profile.walletAddresses.ethereum}
                  </code>
                </div>
              )}
              {profile.walletAddresses.bitcoin && (
                <div className="flex flex-col items-center p-3 rounded-lg border border-gray-200">
                  <div className="flex items-center space-x-2 mb-1">
                    <Bitcoin className="h-5 w-5 text-orange-500" />
                    <span className="font-medium">Bitcoin</span>
                  </div>
                  <code className="text-sm text-gray-600 break-all">
                    {profile.walletAddresses.bitcoin}
                  </code>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}