import { initializeApp, FirebaseOptions } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics, isSupported } from 'firebase/analytics';

// Validate environment variables
function validateFirebaseConfig(): FirebaseOptions {
  const requiredVars = [
    'VITE_FIREBASE_API_KEY',
    'VITE_FIREBASE_AUTH_DOMAIN',
    'VITE_FIREBASE_PROJECT_ID',
    'VITE_FIREBASE_STORAGE_BUCKET',
    'VITE_FIREBASE_MESSAGING_SENDER_ID',
    'VITE_FIREBASE_APP_ID'
  ];

  const missingVars = requiredVars.filter(varName => !import.meta.env[varName]);
  if (missingVars.length > 0) {
    throw new Error(`Missing required Firebase configuration: ${missingVars.join(', ')}`);
  }

  return {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
  };
}

// Initialize Firebase with error handling
function initializeFirebase() {
  try {
    const config = validateFirebaseConfig();
    const app = initializeApp(config);
    const auth = getAuth(app);
    const db = getFirestore(app);
    
    // Initialize analytics only in browser environment
    let analytics = null;
    if (typeof window !== 'undefined') {
      isSupported().then(yes => {
        if (yes) {
          analytics = getAnalytics(app);
        }
      }).catch(error => {
        console.warn('Analytics initialization failed:', error);
      });
    }

    return { app, auth, db, analytics };
  } catch (error) {
    console.error('Firebase initialization failed:', error);
    throw new Error('Failed to initialize Firebase. Please check your configuration.');
  }
}

const { app, auth, db, analytics } = initializeFirebase();

export { app, auth, db, analytics };